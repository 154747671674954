import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Menu, MenuItem, Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { ArrowDropDownSharp } from '@mui/icons-material';

const NavigationMenu = ({ routes }) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [subMenuAnchorEl, setSubMenuAnchorEl] = useState({});
    const [parentIndex, setParentIndex] = useState(null)
    const navigate = useNavigate();
    const location = useLocation();

    const findActiveTab = () => {
        const currentRoute = routes.findIndex(route =>
            route.link === location.pathname ||
            (route.sub_menu && route.sub_menu.some(sub => sub.link === location.pathname))
        );
        return currentRoute !== -1 ? currentRoute : 0;
    };

    useEffect(() => {
        const activeTab = findActiveTab();
        setSelectedTab(activeTab);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
        const selectedRoute = routes[newValue];

        if (!selectedRoute.sub_menu) {
            navigate(selectedRoute.link);
        }
    };

    const handleMenuOpen = (event, index) => {
        setParentIndex(index)
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleSubMenuOpen = (event, id) => {
        setSubMenuAnchorEl((prevState) => ({
            ...prevState,
            [id]: event.currentTarget,
        }));
    };

    const handleSubMenuClose = (id) => {
        setSubMenuAnchorEl((prevState) => ({
            ...prevState,
            [id]: null,
        }));
    };

    const renderSubMenu = (items, parentId = '') => {
        return items.map((item) => {
            const hasSubMenu = item.sub_menu && item.sub_menu.length > 0;
            const itemId = parentId + item.id;

            const isActive = (parentId) => {
                if (parentId !== '') {
                    return location.pathname.includes(item.id)
                }
                console.log(location.pathname.includes(item.id))
                return false
            }
            // bool thisActive = isActive

            return (
                <div key={itemId}>
                    <MenuItem
                    selected={isActive()}
                        onClick={(event) => {
                            if (!hasSubMenu) {
                                handleSubMenuClose(parentId);
                                navigate(item.link);
                            }
                        }}
                        onMouseEnter={(event) => hasSubMenu && handleSubMenuOpen(event, itemId)}
                    >
                        {item.name}
                        {hasSubMenu && <ArrowDropDownSharp />}
                    </MenuItem>
                    {hasSubMenu && (
                        <Menu
                            indicatorColor='secondary'
                            anchorEl={subMenuAnchorEl[itemId]}
                            open={Boolean(subMenuAnchorEl[itemId])}
                            onClose={() => handleSubMenuClose(itemId)}
                            MenuListProps={{
                                onMouseLeave: () => handleSubMenuClose(itemId),
                            }}
                        >
                            {renderSubMenu(item.sub_menu, itemId)}
                        </Menu>
                    )}
                </div>
            );
        });
    };

    return (
        <Box>
            <Tabs
                textColor='secondary'
                indicatorColor='secondary' sx={{
                    mr: 'auto', width: '100%', "& .Mui-selected": { color: 'secondary !important' },
                    "& .css-1xkultq-MuiButtonBase-root-MuiTab-root.Mui-selected": { color: 'secondary !immportant' },
                    "& .css-1pyd7p9-MuiButtonBase-root-MuiTab-root.Mui-selected": { color: 'secondary !immportant' }
                }} value={selectedTab} onChange={handleTabChange} aria-label="navigation tabs">
                {routes.map((route, index) => (
                    <Tab
                        key={route.id}
                        label={route.name}
                        style={{ textTransform: 'none' }}
                        onMouseEnter={(event) => {
                            if (route.sub_menu) {
                                handleMenuOpen(event, index);
                            }
                        }}
                        sx={{ fontWeight: '600', mr: 'auto', fontSize: '1rem', color: selectedTab === index ? 'secondary' : '#fff' }}
                        icon={route.sub_menu && <ArrowDropDownSharp />}
                        iconPosition={route.sub_menu && 'end'}
                    />
                ))}
            </Tabs>

            {parentIndex !== null && routes[parentIndex]?.sub_menu && (
                <Menu
                    indicatorColor='secondary'
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    MenuListProps={{
                        onMouseLeave: () => handleMenuClose(),
                    }}
                >
                    {renderSubMenu(routes[parentIndex].sub_menu)}
                </Menu>
            )}
        </Box>
    );
};

export default NavigationMenu;
