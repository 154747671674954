import React, { useState, useEffect } from "react";
import Layout from '../components/ui/layout'
import Baner from '../components/ui/baner'
import {
  CircularProgress,
  Grid,
  Tabs,
  Tab,
  Box,
  Container,
  alpha,
} from "@mui/material";
import ReactPlayer from 'react-player';
import { useTranslation } from "react-i18next";
import 'react-h5-audio-player/lib/styles.css';
import axios from "../api/without_user_token";
import { grey } from "@mui/material/colors";
import { VolumeUp } from "@mui/icons-material";
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

function Videos() {
    const { t, i18n } = useTranslation("global");
    const [videos, setVideos] = useState([]);
    const [selectedFile, setSelectedFile] = useState(0)

    useEffect(() => {
        const getVideos = async () => {
            try {
                const response = await axios.get("get_all_media_by_type", {
                    params: { type: "VIDEO", lang: i18n.language },
                    headers: { "Content-Type": "application/json" },
                });
                if (response.status === 200) {
                    setVideos(response.data[i18n.language]);
                }
            } catch (error) {
                console.log("Error occur in getVideos: " + error);
            }
        };

        setVideos(null);
    
        getVideos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [i18n.language]);


    const handleFileChange = (event, newValue) => {
        setSelectedFile(newValue)
    }

    return (
        <Layout>
            <Baner title="Les videos" subTitle="de sensibilisations et informations" />
            <Container sx={{ py: 2 }}>
                <Grid item conatiner xs={12} >
                    <Grid item xs={12} container>
                        <Grid item xs={12} sm={12} sx={{ pt: 1 }} container >
                            {(videos !== null) ? (
                                <Grid item container sx={{ mt: 1, borderRadius: "0", }} >
                                    <Grid item sx={{ height: { xs: 300, sm: 400, md: 500, lg: 550 }, pr: 0.5 }} xs={12} sm={9} md={9}>
                                        <ReactPlayer style={{ minWidth: 1 }} width="100%" height="100%" url={videos[selectedFile]?.link} />
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={3}>
                                        <Box sx={{ bgcolor: '#157200', display: 'flex', py: 1, height: '92%' }}>
                                            <Tabs
                                                orientation="vertical"
                                                variant="scrollable"
                                                textColor="#000"
                                                indicatorColor="#FDF50D"
                                                value={selectedFile}
                                                onChange={handleFileChange}
                                                aria-label="Vertical tabs example"
                                                sx={{ flexGrow: 1, borderRight: 1, borderColor: '#FDF50D' }}
                                                >
                                                {videos.map((file, i) => (
                                                    <Tab key={i} icon={selectedFile === i ? <VolumeUp fontSize="small" /> : null} iconPosition={selectedFile === i ? 'end' : undefined} sx={{ textTransform: 'none', width: '100%', fontWeight: '600', borderRightColor: '#FDF50D', width: '100%', color: selectedFile === i ? "#000" : grey[200], textAlign: 'left', bgcolor: selectedFile === i && alpha('#FDF50D', 1) }} label={file.name} />
                                                ))}
                                            </Tabs>
                                        </Box>
                                    </Grid>
                                </Grid>
                            ) : (<Box variant="div" display="flex" sx={{ width: '100%', height: '100%' }} alignContent="center"> <CircularProgress sx={{ margin: "auto auto" }} /> </Box>)}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    )
}

export default Videos