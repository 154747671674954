import { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { lazy } from 'react';
import Home from './pages/home.js';
import Contact from './pages/contact.js';
import Help from './pages/help.js';
import Archive from './pages/archive.js';
import Media from './pages/media.js';
import Commerce from './pages/commerce.js';
import Transport from './pages/transport.js';
import Dashboard from './pages/admin/dashboard.js'
import Categories from './pages/admin/categories.js';

import Notification from './components/ui/notification.js';
import Loading from './components/ui/loading.js';
import Protected from './components/protected/protected.js';
import EditPassword from './components/ui/user/edit_password.js';
import Market from './pages/market.js';
import Formalities from './pages/formalities.js';
import Procedures from './pages/procedures.js';
import Report from './pages/report.js';
import Doccommerce from  './pages/doccommerce.js';
import Doctransport from  './pages/doctransport.js';
import Bulletin from './pages/bulletin.js';
import AdminCommerce from "./pages/admin/commerce.js"
// export const AdminCommerce = lazy(() => import("./pages/admin/commerce.js"));
import AdminTransport from "./pages/admin/transport.js"
import Affiches from './pages/Affiches.js';
import Audios from './pages/audios.js';
import VideosPublic from './pages/videos.js';
import ReferencesTextes from './pages/references_textes.js';
import Cedeao from './pages/cedeao.js';
import Uemoa from './pages/uemoa.js';
import Ohada from './pages/ohada.js';
import Zlecaf from './pages/zlecaf.js';
import BurkinaFaso from './pages/burkina.js';
import Mali from './pages/mali.js';
import Niger from './pages/niger.js';
import Tchad from './pages/tchad.js';
import Benin from './pages/benin.js';
import CotedIvoire from './pages/cotedivoire.js';
import Gambie from './pages/gambie.js';
import Guinee from './pages/guinee.js';
import GuineeBissau from './pages/guineebissau.js';
import Mauritanie from './pages/mauritanie.js';
import Senegal from './pages/senegal.js';
import Togo from './pages/togo.js';
import CapVert from './pages/capvert.js';
import Events from './pages/events.js';
// export const AdminTransport = lazy(() => import("./pages/admin/transport.js"));
// import Videos from './pages/admin/videos.js';
export const Videos = lazy(() => import('./pages/admin/videos.js'));
// import Emissions from './pages/admin/emissions.js';
export const Emissions = lazy(() => import('./pages/admin/emissions.js'));
// import Magazines from './pages/admin/magazines.js';
export const Magazines = lazy(() => import('./pages/admin/magazines.js'));
// import Images from './pages/admin/images.js';
export const Images = lazy(() => import('./pages/admin/images.js'));
// import Login from './components/ui/user/login.js';
export const Login = lazy(() => import('./components/ui/user/login.js'));

// import Users from './pages/admin/users.js';
export const Users = lazy(() => import('./pages/admin/users.js'));
// import Opportunities from './pages/opportunities.js';
export const Opportunities = lazy(() => import('./pages/opportunities.js'));
// import OpportunitiesAdmin from './pages/admin/opportunities.js'
export const OpportunitiesAdmin = lazy(() => import('./pages/admin/opportunities.js'));
// export const Market = lazy(() => import('./pages/market.js'));
// import Sliders from './pages/admin/sliders.js';
export const Sliders = lazy(() => import('./pages/admin/sliders.js'));
// import LoginPage from './pages/admin/login.js';
export const LoginPage = lazy(() => import('./pages/admin/login.js'));
// import Documents from './pages/admin/documents.js';
export const Documents = lazy(() => import('./pages/admin/documents.js'));
// import QRCodePage from './pages/qrcodepage.js';
export const QRCodePage = lazy(() => import('./pages/qrcodepage.js'));
// export const Formalities = lazy(() => import('./pages/formalities.js'));
// export const Procedures = lazy(() => import('./pages/procedures.js'));
// import Policy from './pages/policy.js';
export const Policy = lazy(() => import('./pages/policy.js'));

export const Conditions = lazy(() => import('./pages/admin/conditions.js'))
export const Guides = lazy(() => import('./pages/admin/guides.js'))
export const Event = lazy(() => import('./pages/admin/event.js'))
export const Countries = lazy(() => import('./pages/admin/countries.js'))
export const Organisations = lazy(() => import('./pages/admin/organisations.js'))
export const References = lazy(() => import('./pages/admin/references.js'))

function App() {
  return (
    <>
      <BrowserRouter>
        <Loading />
        <Notification />
        <Login />
        <EditPassword />
        <Suspense>
        <Routes>
          <Route index element={<Home />} />
          <Route path='/' element={<Home />} />
          <Route path='/resources/procedures-commerciales' element={<Archive />} />
          <Route path='/resources/bulletin-information' element={<Bulletin />} />
          <Route path='/resources/report' element={<Report />} />
          <Route path='/resources/commerce' element={<Doccommerce />} />
          <Route path='/resources/transport' element={<Doctransport />} />
          {/* <Route path='/resources' element={<Archive />} /> */}
          <Route path='/messages/commerce' element={<Commerce />} />
          <Route path='/messages/transport' element={<Transport />} />
          <Route path='/formproc/formalities' element={<Formalities />} />
          <Route path='/formproc/procedures' element={<Procedures />} />
          <Route path='/help' element={<Help />} />
          <Route path='/media' element={<Media />} />
          <Route path='/contacts' element={<Contact />} />
          <Route path='/opportunities' element={<Opportunities />} />
          <Route path='/market' element={<Market />} />
          <Route path='/commerce' element={<Commerce />} />
          <Route path='/users' element={<Users />} />
          <Route path="/qrcode" element={<QRCodePage />} />
          <Route path='/policy' element={<Policy />} />

          <Route path='/audiovisu/affiches' element={<Affiches />} />
          <Route path='/audiovisu/audios' element={<Audios />} />
          <Route path='/audiovisu/videos' element={<VideosPublic />} />
          <Route path='/audiovisu/references' element={<ReferencesTextes />} />

          <Route path='/textes/organisations/cedeao' element={<Cedeao />} />
          <Route path='/textes/organisations/uemoa' element={<Uemoa />} />
          <Route path='/textes/organisations/ohada' element={<Ohada />} />
          <Route path='/textes/organisations/zlecaf' element={<Zlecaf />} />

          <Route path='/textes/country/bf' element={<BurkinaFaso />} />
          <Route path='/textes/country/ml' element={<Mali />} />
          <Route path='/textes/country/ne' element={<Niger />} />
          <Route path='/textes/country/td' element={<Tchad />} />
          <Route path='/textes/country/bj' element={<Benin />} />
          <Route path='/textes/country/ci' element={<CotedIvoire />} />
          <Route path='/textes/country/gm' element={<Gambie />} />
          <Route path='/textes/country/gn' element={<Guinee />} />
          <Route path='/textes/country/gw' element={<GuineeBissau />} />
          <Route path='/textes/country/mr' element={<Mauritanie />} />
          <Route path='/textes/country/sn' element={<Senegal />} />
          <Route path='/textes/country/tg' element={<Togo />} />
          <Route path='/textes/country/cv' element={<CapVert />} />
          <Route path='/events' element={<Events />} />

          <Route>
            <Route path="/admin/*" element={ <Protected> <Dashboard /> </Protected> } />
            <Route path='/admin/dashboard' element={<Protected> <Dashboard /> </Protected>} />
            <Route path='/admin/opportunities' element={<Protected> <OpportunitiesAdmin /> </Protected>} />
            <Route path='/admin/categories' element={<Protected><Categories /></Protected>} />
            <Route path='/admin/commerce' element={<Protected><AdminCommerce /></Protected>} />
            <Route path='/admin/transport' element={<Protected><AdminTransport /></Protected>} />
            <Route path='/admin/videos' element={<Protected><Videos /></Protected>} />
            <Route path='/admin/emissions' element={<Protected><Emissions /></Protected>} />
            <Route path='/admin/magazines' element={<Protected><Magazines /></Protected>} />
            <Route path="/admin/images" element={<Protected><Images /></Protected>} />
            <Route path="/admin/users" element={<Protected><Users /></Protected>} />
            <Route path="/admin/sliders" element={<Protected><Sliders /></Protected>} />
            <Route path="/admin/docs" element={<Protected><Documents /></Protected>} />
            <Route path='/admin/login' element={<LoginPage />} />
            <Route path='/admin/conditions' element={<Protected> <Conditions /> </Protected>} />
            <Route path='/admin/guides' element={<Protected> <Guides /> </Protected>} />
            <Route path='/admin/events' element={<Protected> <Event /> </Protected>} />
            <Route path='/admin/countries' element={<Protected> <Countries /> </Protected>} />
            <Route path='/admin/organisations' element={<Protected> <Organisations /> </Protected>} />
            <Route path='/admin/references' element={<Protected> <References /> </Protected>} />
          </Route>
        </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
