import { useEffect, useState } from "react";
import Baner from "../components/ui/baner";
import { Box, CircularProgress, Container } from "@mui/material";
import Layout from "../components/ui/layout";
import Bg from "../images/form_bg.png"
import { useTranslation } from "react-i18next";
import axios from '../api/without_user_token'

export default function Procedures() {
    const [content, setContent] = useState()
    const { t, i18n } = useTranslation('global');

    const fetchContent = () => {
        axios.get('get_all_condition_guide', {
            headers: { 'Content-Type': 'application/json' }
        }).then((response) => {
            if (response.status === 200) {
                const filtered = {
                    en: response.data?.en.filter(item => item.type === "CONDITION"),
                    fr: response.data?.fr.filter(item => item.type === "CONDITION"),
                    ar: response.data?.ar?.filter(item => item.type === "CONDITION"),
                    pt: response.data?.pt?.filter(item => item.type === "CONDITION"),
                }
                setContent(filtered)
            }
        }).catch((err) => {
            console.log(err.message)
        })
    }

    useEffect(() => {
        fetchContent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        document.title = t('menu.guides');
    }, [t]);

    return (
        <Layout>
            <Baner title={t('menu.guides')} subTitle="" />
            <Box sx={{ width: '100%', backgroundImage: `url(${Bg})`, backgroundSize: 'cover', backgroundPosition: 'top', backgroundRepeat: 'no-repeat' }}>
            <Container sx={{py: 4, backgroundColor: 'white'}}>
                {content !== undefined ? (
                    <div style={{backgroundColor: 'white'}} dangerouslySetInnerHTML={{__html: content[i18n.language][0].describe}} />
                ) : (
                    <CircularProgress />
                )}
            </Container>
            </Box>
        </Layout>
    )
}