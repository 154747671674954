/* eslint-disable react/jsx-no-comment-textnodes */
import { useEffect } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import LogoCilss from "../../../images/cilssdef.png"

import Dashboard from '@mui/icons-material/Dashboard';
import VideoLibrary from '@mui/icons-material/VideoLibrary';
import File from '@mui/icons-material/FileCopy';
import Slide from '@mui/icons-material/BurstMode';
import Group from '@mui/icons-material/Group';
import Flag from '@mui/icons-material/Flag';
import Diversity from '@mui/icons-material/Diversity3';
import Gavel from '@mui/icons-material/Gavel';
import FollowTheSigns from '@mui/icons-material/FollowTheSigns';
import EventIcon from '@mui/icons-material/Event';
import Audiotrack from '@mui/icons-material/Audiotrack';
import ImageIcon from '@mui/icons-material/Image';
import Folder from '@mui/icons-material/FolderCopy';

import { usePathname } from './../../../routes/hooks/use-pathname';

import { RouterLink } from '../../../routes/components';

import { useResponsive } from '../../../hooks/use-responsive';

import Scrollbar from '../../scrollbar';

import { NAV } from './config-layout';
import { useTranslation } from 'react-i18next';
import { Divider } from '@mui/material';
import { green } from '@mui/material/colors';

import ProjectLogo from "../../../images/logodef.png"
import { useValue } from '../../../context/ContextProvider';


// ----------------------------------------------------------------------





export default function Nav({ openNav, onCloseNav }) {
  const {t} = useTranslation('global')
  const {state: {currentUser}} = useValue()
  const pathname = usePathname();
  
  const upLg = useResponsive('up', 'lg');
  
  const navConfig = [
    {
      title: t('admin.dashboard'),
      path: '/admin/dashboard',
      icon: <Dashboard />
    },
    {
      title: t('menu.countries'),
      path: '/admin/countries',
      icon: <Flag />,
    },
    {
      title: t('menu.organisations'),
      path: '/admin/organisations',
      icon: <Diversity />,
    },
    {
      title: t('menu.conditions'),
      path: '/admin/conditions',
      icon: <Gavel />,
    },
    {
      title: t('menu.guides'),
      path: '/admin/guides',
      icon: <FollowTheSigns />,
    },
    {
      title: t('menu.ref'),
      path: '/admin/references',
      icon: <Folder />,
    },
    {
      title: t('menu.events'),
      path: '/admin/events',
      icon: <EventIcon />,
    },
    {
      title: t('theques.Videos'),
      path: '/admin/videos',
      icon: <VideoLibrary />
    },
    {
      title: t('theques.radio_broadcast'),
      path: '/admin/emissions',
      icon: <Audiotrack />,
    },
    {
      title: t('admin.title_docuement'),
      path: '/admin/docs',
      icon: <File />,
    },
    {
      title: t('admin.images'),
      path: '/admin/images',
      icon: <ImageIcon />,
    },
  ];

  const adminConfig = [
    {
      title: t('admin.title_slider'),
      path: '/admin/sliders',
      icon: <Slide />,
    },
    {
      title: t('admin.title_user'),
      path: '/admin/users',
      icon: <Group />
    },
  ]

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderMenu = (
    <Stack component="nav" spacing={0.1} sx={{ px: 2, py: 1 }}>
      {navConfig.map((item) => (
        <NavItem key={t(`admin.${item.title}`)} item={item} />
      ))}
    </Stack>
  );


  const renderAdminMenu = (
    <Stack component="nav" spacing={0.5} sx={{ px: 2 }}>
      {adminConfig.map((item) => (
        <NavItem key={t(`admin.${item.title}`)} item={item} />
      ))}
    </Stack>
  );

  const renderCilssLogo = (
    <Box variant="div" display='flex' justifyContent='center' sx={{width: '100%', mt: '1rem'}}>
      <img style={{width: '50%', height: 'auto'}} src={LogoCilss} alt="CILSS" />
    </Box>
  )

  const renderProjectLogo = (
    <Box variant="div" display='flex' justifyContent='center' sx={{width: '100%', mt: '1rem'}}>
      <img style={{width: '50%', height: 'auto'}} src={ProjectLogo} alt="CILSS" />
    </Box>
  )

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >

      <Box variant="div" justifyContent="center" display="flex">
      {renderCilssLogo}
      {/* {renderCilssLogo} */}
      {renderProjectLogo}
      </Box>

      {renderMenu}

      {currentUser?.userRole === "Admin" && (
        <Box>
        <Typography sx={{ml: '1rem', color: 'white'}}>
          {t("admin.title_admin")}
        </Typography>
        <Divider />
  
        {renderAdminMenu}
  
        {/* <Typography sx={{ml: '1rem', color: 'white'}}>
          {t("admin.title_system")}
        </Typography>
        <Divider />
  
        
        {renderSystemMenu} */}
        </Box>
      )}

      <Box sx={{ flexGrow: 1 }} />

      {/* {renderCilssLogo} */}

    </Scrollbar>
  );


  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.WIDTH },
      }}
    >
      {upLg ? (
        <Box
          sx={{
            height: 1,
            position: 'fixed',
            bgcolor: '#157200',
            width: NAV.WIDTH,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Box>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.WIDTH,
              bgcolor: '#157200',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

// ----------------------------------------------------------------------

function NavItem({ item }) {
  const pathname = usePathname();

  const active = item.path === pathname;

  return (
    <ListItemButton
      component={RouterLink}
      href={item.path}
      sx={{
        minHeight: 44,
        typography: 'body2',
        fontSize: '0.9rem',
        color: green[50],
        textTransform: 'capitalize',
        fontWeight: 'fontWeightSemiBold',
        ...(active && {
          color: '#fff',
          fontWeight: 'fontWeightBold',
          bgcolor: (theme) => alpha('#f2c11f', 0.48),
          '&:hover': {
            bgcolor: (theme) => alpha(theme.palette.grey[100], 0.16),
          },
        }),
      }}
    >
      <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
        {item.icon}
      </Box>

      <Box component="span">{item.title} </Box>
    </ListItemButton>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};
