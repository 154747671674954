import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import i18next from 'i18next';

import global_en from "./components/lang/en/global.json";
import global_fr from "./components/lang/fr/global.json";
import global_ar from "./components/lang/ar/global.json";
import global_pt from "./components/lang/pt/global.json";
import { I18nextProvider } from 'react-i18next';
import ContextProvider from './context/ContextProvider';
import { ThemeProvider, createTheme } from '@mui/material';
import axios from 'axios';

i18next.init({
  interpolation: {escapeValue: false},
  lng: "fr",
  resources: {
    en: {
      global: global_en
    },
    fr: {
      global: global_fr
    },
    ar: {
      global: global_ar
    },
    pt: {
      global: global_pt
    }
  }
})

const theme = createTheme({
  palette: {
    primary: {
      main: "#157200",
    },
    secondary: {
      main: "#FDE50D"
    }
  }
})

axios.defaults.baseURL = "https://backend.plateform-dtrc.net:8443/api/"
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.interceptors.request.use(function(config){
  const list_url = [
    'show_domains', 'get_domain_name', 'get_data_text_by_domaine_name',
    'get_data_by_domaine_name_and_sub_domain', 'get_domains', 'read_sub_domain',
    'get_sub_domains', 'show_data_text', 'read_data_text', "get_most_read_data_text",
    'get_data_text', 'get_document', 'get_carousels', 'get_customer', 'get_media',
    'get_organization', 'get_users', 'get_awareness', 'get_all_awareness',
    'get_magazine_by_lang', 'show_magazine', 'get_all_magazine', 'get_media_list',
    'get_all_data_text_by_domaine_name', 'show_opportunity', 'get_opportunity_by_lang',
    'get_all_opportunity', 'get_all_media_by_type', 'get_all_item_count',
    'count_unvalidate_data_text', 'get_magazine_by_lang', 'add_customer'
  ]
  
  const token         = JSON.parse(localStorage.getItem('currentUser')).token
  const token_bearer  = 'Bearer mHf1toDHlu0gcjHmCgAr2k6jp7sWUc0DzvB79gjX9aLLwMpqrX3YS3LQJUU3TQeWk9lTb8D003vE9T8f1TelRuggRUliKUXGDQLiWAZMFMZYVAEdMI4tEwGp';
  config.headers.Authorization = list_url.includes(config.url) ? `${token_bearer}` : `Token ${token}`;

	return config;
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ContextProvider>
      <I18nextProvider i18n={i18next}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </I18nextProvider>
    </ContextProvider>
);

